.sidebar{
  position : fixed;
  height : 100%;
  width: 14rem;
  background: #4e73df;
  top:0; 
  z-index: 1;
  transition: width 300ms;
}

.sidebar ul{
  margin : 0;
  padding : 0;
}

.sidebar ul li {
  list-style-type: none;
}

.sidebar ul li a {
  text-decoration: none;
  color : #e9e9e9;
  width : 100%;
  display: block;
  padding: 7px 0px 10px 15px;
  height : 100%;
  font-size : 1rem;
  text-transform: uppercase;
}

.sidebar ul li a:hover{
  color : #ffffff;
}

.sidebar ul li a i{
  font-size: 1.1em; 
  padding : 0;
}

.sidebar ul li a span{
  font-size: 1em;
  margin-left : 20px;
}

.brand{
  display : flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color : #ffffff;
  height : 80px;
  width : 100%;
  transition: width 300ms;
  text-transform: uppercase;
  text-decoration: none;
  font-size : 1.3rem;
}

.brand:hover{
  color : #ffffff;
  text-decoration: none;
}

.sidebar-divider{
  width : 87%;
  background : #ffffff;
  margin-top : 0;
}

/* sidebar hidden */
.sidebar-hidden{
  display:none;
}



/* header */
.header{
  position : fixed;
  top : 0;
  background : #2d98da;
  color : white;
  width :100%;
  text-align : right;
  height : 80px;
  z-index: 2;
  box-shadow: 0 3px 5px -5px #888;
}

.username{
  padding : 10px 20px 0 0;

}

.header i{
  font-size: 1.3rem;
  margin-top: 10px;
  margin-right : 5px;
  
}

.header a{
  margin:0;
  font-size : 1.3rem;
  
}

.menufold{
  cursor: pointer;
  margin-left : 14rem;
  margin-top : 25px !important;
  float : left;
}

.menufold-hidden{
  margin-left : 0;
}

/* header end */

/* content */
.content{
  margin : 0 0.5rem 0 14.5rem; 
  padding : 0 10px;
  transition : margin 300ms;

}
.content-hidden{
  margin: 0 0.5rem 0 0.5rem;
  transition : margin 300ms;
}

.content .title{
  color : #1890ff;
  font-size : 1.3rem;
  letter-spacing: 0;
  text-transform: uppercase;
  display : block;
  margin-bottom : 15px;
}

.wrapper {
  background : #ffffff;
  padding : 10px 20px;
  width : 100%;
  border-radius : 2px;
  margin: 10px 0 10px 0 ;
}


.wrapper-form-title{
  text-align : center;
  margin-bottom : 20px;
  margin-top : 15px;
}

.active-link{
  color : #ffffff !important;
}

.username-info{
  text-transform: uppercase;
}

.brand-name{
  float : left;
  font-size : 1.6rem;
  letter-spacing: 0;
  text-transform: uppercase;
  margin : 20px 0px 0px 10px;
}

