.ar-column:not(td){
  background : #ecf0f1 !important;
}

.ant-table-thead tr th{
  white-space : nowrap !important;
  text-align: center;
  background-color : #343a40d2;
  color : #ffffff;
}

.ant-table-tbody tr:nth-child(even){
  background-color : #f2f2f2 ;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td{
  background-color : #f2f2f2;
}

.ant-table-body{
  margin : 0 !important;
}

.nowrap{
  white-space: nowrap;
}

.ant-table-tbody tr td{
  white-space : nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-table-tbody tr td:hover{
  overflow: visible;
  white-space:normal;
  word-wrap: break-word;
}