
@font-face {
  font-family: OpenSans-Regular;
  src: url('./assets/fonts/OpenSans/OpenSans-Regular.ttf'); 
}

body {
  background-color : #e7e7e9;
  margin : 0;
  padding-top : 85px;

  font-family: OpenSans-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
